import { SlackChannelSelect } from '@/components/SlackChannelSelect';
import { Loader } from '@/components/loader';
import { Label } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { SelectAsync } from '@/components/ui/select';
import {
    GroupOrUserResLinkOption,
    ReslinkValue,
    UserAndGroupResLinkValueContainer,
} from '@/components/ui/select/UserResLinkComponents';
import { useUpdateErrorEscalationSettings } from '@/hooks/mutations/settings/useErrorEscalationSettings';
import { useErrorEscalationSettings } from '@/hooks/queries/settings/useErrorEscalationSettings';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { SectionHeader } from '@pages/settings/components/Approvals/Approvals';
import type { EscalateConfigResponseType } from 'lib/models/settings/escalate';
import { TriangleAlert } from 'lucide-react';
import qs from 'qs';
import { useState } from 'react';
import type { GroupBase } from 'react-select';
import type { LoadOptions } from 'react-select-async-paginate';

const entryToOption = (entry: { id: string; type: 'user' | 'group'; avatar: string | null; name: string }) => {
    return {
        type: entry.type,
        label: entry.name,
        value: entry.id,
        avatar: entry.avatar,
    };
};

const selectionToOption = (
    selection: EscalateConfigResponseType['cnsl_error_escalation_setting_dm_selection'][number],
) => {
    return {
        type: selection.cnsl_user_id ? ('user' as const) : ('group' as const),
        label: (selection.cnsl_user_id ? selection.cnsl_user?.displayName : selection.cnsl_group?.name) || '',
        value: (selection.cnsl_user_id ?? selection.cnsl_group_id) as string,
        avatar: (selection.cnsl_user_id ? selection.cnsl_user?.avatar : selection.cnsl_group?.logo) || null,
    };
};

export const EscalationSettingForm = ({ config }: { config: EscalateConfigResponseType }) => {
    const { mutate: updateSettings } = useUpdateErrorEscalationSettings();
    const [selectedUsersOrGroups, setSelectedUsersOrGroups] = useState<ReturnType<typeof entryToOption>[]>(
        config.cnsl_error_escalation_setting_dm_selection.map(selectionToOption),
    );
    const [selectedOption, setSelectedOption] = useState<'slack' | 'message' | null>(
        config.external_slack_channel_id
            ? 'slack'
            : config.cnsl_error_escalation_setting_dm_selection.length > 0
              ? 'message'
              : null,
    );
    const [selectedChannel, setSelectedChannel] = useState<{ label: string; value: string } | null>(
        config.slack_channel ? { label: config.slack_channel.name, value: config.slack_channel.id } : null,
    );

    return (
        <div className="flex flex-col gap-xl">
            <section className="border-grey border rounded-md">
                <SectionHeader
                    heading="IT Team Escalation"
                    description="How Console will notify you if something goes wrong or needs input from an IT admin"
                    icon={<TriangleAlert />}
                    iconBg="bg-bg-red-secondary text-body-white"
                />
                <RadioGroup
                    value={selectedOption ?? undefined}
                    onValueChange={v => {
                        setSelectedOption(v as 'slack' | 'message');
                        if (v === 'slack') {
                            setSelectedUsersOrGroups([]);
                            updateSettings({
                                notify_selection: [],
                            });
                        } else if (v === 'message') {
                            setSelectedChannel(null);
                            updateSettings({
                                external_slack_channel_id: null,
                            });
                        }
                    }}
                >
                    <div className="flex flex-col p-lg gap-lg">
                        <div className="flex gap-md">
                            <RadioGroupItem value="message" className="mt-[3.5px]" />
                            <div className="flex flex-col gap-sm">
                                <Label className="font-medium">Send a message in Slack</Label>
                                <SelectAsync
                                    isMulti={true}
                                    isDisabled={selectedOption !== 'message'}
                                    value={selectedUsersOrGroups}
                                    placeholder="Select Users and Groups"
                                    isClearable={false}
                                    components={{
                                        Option: GroupOrUserResLinkOption,
                                        SingleValue: ReslinkValue,
                                        ValueContainer: UserAndGroupResLinkValueContainer,
                                    }}
                                    loadOptions={
                                        (async (search: string, _, additional) => {
                                            const res = await baseFetch<{
                                                options: {
                                                    id: string;
                                                    type: 'user' | 'group';
                                                    avatar: string | null;
                                                    name: string;
                                                }[];
                                            }>(
                                                `/api/v1/groups/with-users?${qs.stringify({ search, cursor: additional?.cursor })}`,
                                                {
                                                    headers: getHeaders(),
                                                },
                                            );
                                            return {
                                                options: res.options.map(entryToOption),
                                                hasMore: res.options.length > 0,
                                                additional: {
                                                    cursor:
                                                        res.options.length > 0
                                                            ? res.options[res.options.length - 1].name
                                                            : undefined,
                                                },
                                            };
                                        }) as LoadOptions<
                                            ReturnType<typeof entryToOption>,
                                            GroupBase<ReturnType<typeof entryToOption>>,
                                            { cursor: string | undefined }
                                        >
                                    }
                                    onChange={value => {
                                        /* TS not liking non-readable nature of react value */
                                        setSelectedUsersOrGroups(value as ReturnType<typeof entryToOption>[]);
                                        updateSettings({
                                            notify_selection: value.map(({ value, type }) => ({
                                                id: value,
                                                type,
                                            })),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-md">
                            <RadioGroupItem value="slack" className="mt-[3.5px]" />

                            <div className="flex flex-col gap-sm">
                                <Label className="font-medium">Post in Slack channel</Label>
                                <SlackChannelSelect
                                    isMulti={false}
                                    isDisabled={selectedOption !== 'slack'}
                                    selected={selectedChannel ?? undefined}
                                    onChange={value => {
                                        if (value && 'value' in value) {
                                            setSelectedUsersOrGroups([]);
                                            setSelectedChannel(value);
                                            updateSettings({
                                                external_slack_channel_id: value.value,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </RadioGroup>
            </section>
        </div>
    );
};

export const EscalationSetting = () => {
    const { data } = useErrorEscalationSettings();
    return <>{data?.config ? <EscalationSettingForm config={data.config} /> : <Loader />}</>;
};
