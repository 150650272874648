import { SelectAsync } from '@/components/ui/select/index';
import { baseFetch } from '@/lib/baseFetch';
import { getHeaders } from '@/lib/getHeaders';
import { IntegrationId } from 'lib/integration';
import type { PaginatedListResponse } from 'lib/models/pagination';
import type { Slackchannel } from 'lib/prisma/types';
import qs from 'qs';
import type { GroupBase, MultiValue, SingleValue } from 'react-select';
import type { LoadOptions } from 'react-select-async-paginate';
import { AppControl } from './ui/select/AppControl';
import { GenericValuesContainer } from './ui/select/GenericOptions';

export interface SlackSelectOpt {
    label: string;
    value: string;
}

export type SlackSelectChangePayload = SingleValue<SlackSelectOpt> | MultiValue<SlackSelectOpt>;

interface SlackMultiSelectProps {
    selected?: Array<SlackSelectOpt> | SlackSelectOpt;
    isMulti?: boolean;
    onChange?: (v: SlackSelectChangePayload) => void;
    isDisabled?: boolean;
}

const SlackControl = AppControl(IntegrationId.Slack);

export const SlackChannelSelect = ({ selected, onChange, isDisabled, isMulti }: SlackMultiSelectProps) => {
    return (
        <SelectAsync
            isMulti={isMulti}
            defaultValue={selected}
            value={selected || null}
            isDisabled={isDisabled}
            placeholder="Select Slack channel(s)"
            components={{
                Control: SlackControl,
                MultiValue: () => null,
                ValueContainer: GenericValuesContainer,
            }}
            loadOptions={
                (async (search, _, additional) => {
                    const res = await baseFetch<PaginatedListResponse<Slackchannel>>(
                        `/api/v1/slack/channels?${qs.stringify({ search, cursor: additional?.cursor })}`,
                        {
                            headers: getHeaders(),
                        },
                    );

                    return {
                        options: res.items.map(value => ({
                            label: value.name,
                            value: value.id,
                        })),
                        hasMore: !!res.pagination.nextCursor,
                        additional: {
                            cursor: res.pagination.nextCursor,
                        },
                    };
                }) as LoadOptions<
                    { label: string; value: string },
                    GroupBase<{ label: string; value: string }>,
                    { cursor: string | undefined }
                >
            }
            onChange={onChange}
        />
    );
};
