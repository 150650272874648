import { useJiraGetOAuthUrl } from '@/hooks/queries/useOAuth';
import { getSlackOAuthUrl } from '@/lib/slack';
import { IntegrationId } from 'lib/integration';

export const useProviderEndpoints = () => {
    const { data: jiraOAuthUrl } = useJiraGetOAuthUrl();

    const getProviderPath = (provider: IntegrationId, name: string, args?: { isEnterprise: boolean }) => {
        let providerPath = `/settings/organization/integrations/${name.toLowerCase()}`;

        switch (provider) {
            case IntegrationId.Slack: {
                providerPath = getSlackOAuthUrl(!!args?.isEnterprise);
                break;
            }
            case IntegrationId.Notion:
                providerPath = import.meta.env.VITE_NOTION_AUTHORIZATION_URL;
                break;
            case IntegrationId.Jira:
                providerPath = jiraOAuthUrl?.url ?? '';
                break;
        }

        return providerPath;
    };

    return {
        getProviderPath,
    };
};
