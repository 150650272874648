import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useQueryClient } from '@tanstack/react-query';

export const useSlackTeamUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation<void, DefaultError, { teamId: string }>({
        url: `/api/v1/slack/settings/team`,
        method: 'PUT',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [`/api/v1/slack/settings`], exact: true });
        },
    });
};
