import { useQuery } from '@/lib/hooks/useQuery';
import type { SlackSettingsStatus, SlackTeamsResponse } from 'lib/models/settings/slack';
export const useSlackSettings = () => {
    return useQuery<SlackSettingsStatus>({
        url: `/api/v1/slack/settings`,
    });
};

export const useSlackAvailableTeams = (enabled: boolean) => {
    return useQuery<SlackTeamsResponse>({
        url: `/api/v1/slack/team`,
        enabled,
    });
};
