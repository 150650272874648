import { Loader } from '@/components/loader';
import { Button } from '@/components/ui/button/button';
import { Select } from '@/components/ui/select';
import { useSlackTeamUpdate } from '@/hooks/mutations/useSlackTeam';
import { useSlackAvailableTeams, useSlackSettings } from '@/hooks/queries/useSlackSettings';
import { getSlackOAuthUrl } from '@/lib/slack';
import { SectionHeader } from '@pages/settings/components/Approvals/Approvals';
import { Slack } from 'lucide-react';
import { useEffect, useState } from 'react';

const EnterpriseDetails = ({ teamId, teams }: { teamId: string | null; teams: { id: string; name: string }[] }) => {
    const { mutate: updateTeam } = useSlackTeamUpdate();
    const [team, setTeam] = useState<string | null>(teamId);
    const options = teams.map(t => ({ label: t.name, value: t.id }));
    const selectedOption = options.find(o => o.value === team);
    return (
        <div className="flex flex-col gap-xl">
            <section className="border-grey border rounded-md">
                <SectionHeader
                    heading="Enterprise Slack"
                    description="Console will use your Slack Enterprise connection for advanced actions"
                    icon={<Slack />}
                    iconBg="bg-bg-blue-secondary text-body-white"
                />
                <div className="flex flex-col p-lg gap-lg">
                    <Select
                        options={options}
                        value={selectedOption}
                        onChange={i => {
                            if (i) {
                                setTeam(i.value);
                                updateTeam({ teamId: i.value });
                            }
                        }}
                    />
                </div>
            </section>
        </div>
    );
};

export const SlackEnterpriseDetails = () => {
    const [isEnterprise, setIsEnterprise] = useState(false);
    const { data: teams, isLoading: teamsLoading } = useSlackAvailableTeams(isEnterprise);
    const { data: settings, isLoading: settingsLoading } = useSlackSettings();

    const loading = teamsLoading || settingsLoading;

    useEffect(() => {
        if (settings) {
            setIsEnterprise(settings.isEnterprise);
        }
    }, [settings]);

    if (loading) {
        return <Loader />;
    }

    if (isEnterprise && teams?.teams) {
        return (
            <EnterpriseDetails
                teamId={settings?.team || null}
                teams={teams.teams.filter(t => t.id && t.name).map(t => ({ id: t.id!, name: t.name! }))}
            />
        );
    } else if (!isEnterprise) {
        const url = getSlackOAuthUrl(true);
        return (
            <Button
                onClick={() => {
                    window.location.assign(url);
                }}
            >
                Connect Enterprise Slack
            </Button>
        );
    } else {
        // want to make sure we don't prompt them for reinstall if we just have a fetch error
        return <div>Error locating teams. Contact support if this persists.</div>;
    }
};
